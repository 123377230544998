<script setup lang="ts">
import type { MenuItemLayout } from '~/src/enums/MenuItem'
import { TagType } from '~/src/enums/Tag'
import Tag from '~/components/molecules/Tag.vue'

interface Props {
  layout: MenuItemLayout
  active?: boolean
}

const props = defineProps<Props>()
</script>

<template>
  <button class="menu-item" :class="[`menu-item--${props.layout}`, props.active ? 'menu-item--active' : '']">
    <span class="menu-item__icon" :class="{'hidden': !$slots.icon}">
      <slot name="icon" />
    </span>
    <slot />
    <Tag v-if="$slots.notifications" :type="TagType.Secondary" class="h-4 py-0 leading-none bg-rose text-body-regular-12 text-accent border border-accent/[.32]">
      <slot name="notifications" />
    </Tag>
  </button>
</template>
<style scoped lang="postcss">
.menu-item {
  @apply cursor-pointer select-none grid grid-flow-col items-center auto-cols-max text-black-100;

  &--vertical {
    @apply px-4 py-2 gap-2 border-l-2 border-black-5 text-button w-full;

    &.menu-item--active {
      @apply text-accent border-accent;
    }
  }

  &--horizontal {
    @apply px-2 py-3 gap-2 border-b-2 border-transparent text-other;

    &.menu-item--active {
      @apply text-accent border-accent;
    }
  }
}
</style>
